import React from 'react';
import {
  Text,
  Badge,
  Box,
  Heading,
  HStack,
  Wrap,
  WrapItem,
  Button,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import DownloadButton from './DownloadButton';
import { getFile } from 'store/products';
import { fomratNumberSpace } from 'helpers';

export const MultiOptionField = ({
  header,
  elements,
  gridColumn,
}: {
  header: string;
  elements: string[] | undefined;
  gridColumn: any;
}): JSX.Element => {
  return (
    <Box gridColumn={gridColumn}>
      <Heading fontSize='md' mb={2} marginInlineEnd={3}>
        {header}
      </Heading>
      <HStack spacing={2}>
        <Wrap>
          {elements ? (
            elements.map((element, index) => (
              <WrapItem key={index}>
                <BadgeT key={index} element={element} />
              </WrapItem>
            ))
          ) : (
            <Text fontSize='sm'>-</Text>
          )}
        </Wrap>
      </HStack>
    </Box>
  );
};

export const BadgeT = ({ element }: { element: string }): JSX.Element => {
  return (
    <Badge key={element} fontSize='sm' px={1}>
      {element}
    </Badge>
  );
};

export const SingleField = ({
  header,
  value,
  gridColumn,
  isDate,
}: {
  header: string;
  value: string | number | null | undefined;
  gridColumn: any;
  isDate?: boolean;
}): JSX.Element => {
  if (value === undefined || value === '' || value === null) {
    value = 'Brak';
  }
  return (
    <Box gridColumn={gridColumn}>
      <Heading fontSize='md' mb={2} marginInlineEnd={3}>
        {header}
      </Heading>
      <Text fontSize='md'>{isDate ? value : fomratNumberSpace(value)}</Text>
    </Box>
  );
};

export const FileField = ({
  header,
  value,
  gridColumn,
}: {
  header: string;
  value: {
    files: number[];
    providedUrls: string[];
  };
  gridColumn: any;
}): JSX.Element => {
  const { files, providedUrls } = value;

  const hasFiles = files && files.length > 0;
  const hasUrls = providedUrls && providedUrls.length > 0;

  return (
    <Box ml={4} gridColumn={gridColumn}>
      <Heading fontSize='md' mb={1}>
        {header}
      </Heading>
      {hasUrls && (
        <Text fontSize='sm' mb={2}>
          Dostarczone linki:
        </Text>
      )}
      {hasUrls &&
        providedUrls?.map((url, index) => (
          <Button
            as={Link}
            to={{ pathname: url }}
            key={index}
            fontSize='sm'
            target='_blank'
            mb={2}
          >
            Link {index + 1}
          </Button>
        ))}
      {hasFiles && (
        <Text fontSize='sm' mb={2}>
          Pliki:
        </Text>
      )}
      {hasFiles &&
        files?.map(fileId => (
          <DownloadButton
            action={getFile({ fileId })}
            title='Pobierz'
            description='Pobierz plik'
            key={fileId}
          />
        ))}
      {!hasFiles && !hasUrls && <Text fontSize='sm'>Brak</Text>}
    </Box>
  );
};
